import React from 'react';
import { Link } from '@reach/router';

import './style.scss';

import MailingList from '../MailingList/';
import Warning from '../Warning/';

import freight from './Freight.png';
import waterTaxi from './WaterTaxi.png';
import ferry from './Ferry.png';

const Footer = (props) => (
  <footer className="d-print-none">
    <div className="cross-site">
      <div className="container">
        <div className="row equal">
          <div className="col-md-6">
            <p>
              Fire Island <span className="text-freight strong">Freight</span>
            </p>
            <h2>Fire Island Shipping Solutions</h2>
            <p>
              Moving in? Re-modeling? Renting for the week? We have the shipping
              solutions you need. Sign up for a freight account online today and
              leave your cash at home.
            </p>
            <Link to="/info/freight" className="btn btn-outline-freight">
              More Info
            </Link>
            <div className="img-container">
              <img src={freight} alt="Fire Island Freight" />
            </div>
          </div>
          <div className="col-md-6">
            <p>
              Fire Island{' '}
              <span className="text-water-taxi strong">Water Taxi</span>
            </p>
            <h2>Beach to beach just minutes away.</h2>
            <p>
              Expand your Fire Island adventure and enjoy dinner in Ocean Beach
              or a walking tour of the Sunken Forest. The fastest way to travel
              between communities.
            </p>
            <a
              href="http://www.fireislandwatertaxi.com"
              className="btn btn-outline-water-taxi"
            >
              More Info
            </a>
            <div className="img-container">
              <img src={waterTaxi} alt="Fire Island Water Taxi" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-links">
      <div className="debut-texture" />
      <div className="container">
        <div className="row">
          <div className="col-md-2 col-sm-6">
            <h2>Schedules</h2>
            <Link to="/schedules/kismet/">Kismet</Link>
            <Link to="/schedules/saltaire">Saltaire</Link>
            <Link to="/schedules/fair-harbor">Fair Harbor</Link>
            <Link to="/schedules/atlantique">Atlantique</Link>
            <Link to="/schedules/dunewood">Dunewood</Link>
            <Link to="/schedules/ocean-beach">Ocean Beach</Link>
            <Link to="/schedules/seaview">Seaview</Link>
            <Link to="/schedules/ocean-bay-park">Ocean Bay Park</Link>
          </div>
          <div className="col-md-2 col-sm-6">
            <h2>Info</h2>
            <Link to="/updates/">Updates</Link>
            <Link to="/info/fares/">Fares</Link>
            <Link to="/info/parking/">Parking</Link>
            {/* <Link to="/">Deals</Link> */}
            {/* <Link to="/">Events</Link> */}
            <Link to="/info/contact/">Contact</Link>
            {/* <Link to="/">Weddings</Link> */}
            <Link to="/charters">Charters</Link>
            <Link to="/info/faq/">FAQ</Link>
            <a href="https://shop.fireislandferries.com/" target="_blank">
              Shop
            </a>
            <a href="http://www.fimtickets.com" target="_blank">
              mTickets
            </a>
          </div>
          <div className="col-md-2 col-sm-6">
            <h2>Directory</h2>
            <Link to="/directory">Restaurants</Link>
            <Link to="/directory">Hotels</Link>
            <Link to="/directory">Services</Link>
            <Link to="/directory">Retail</Link>
            <Link to="/directory">Construction</Link>
            <Link to="/directory">More...</Link>
          </div>
          <div className="col-md-2 col-sm-6">
            <h2>Communities</h2>
            <Link to="/communities/kismet">Kismet</Link>
            <Link to="/communities/saltaire">Saltaire</Link>
            <Link to="/communities/fair-harbor">Fair Harbor</Link>
            <Link to="/communities/atlantique">Atlantique</Link>
            <Link to="/communities/dunewood">Dunewood</Link>
            <Link to="/communities/ocean-beach">Ocean Beach</Link>
            <Link to="/communities/seaview">Seaview</Link>
            <Link to="/communities/ocean-bay-park">Ocean Bay Park</Link>
          </div>
          <div className="col-md-4">
            <h2>Be in the know!</h2>
            <p>
              Join our mailing list and stay up to date on service disruptions,
              schedules, and more.
            </p>
            <MailingList />
            <h2>Contact</h2>
            <p className="contact">
              <i className="far fa-map-marker" /> 99 Maple Ave. Bay Shore, NY
              11706
            </p>
            <p className="contact">
              <i className="far fa-phone" /> 631.665.3600
            </p>
            <a href="mailto:info@fireislandferries.com">
              <i className="far fa-envelope-open" /> info@fireislandferries.com
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-close">
      <div className="container">
        <div className="row">
          <div className="col-md text-center align-self-center">
            <img src={ferry} alt="Fire Island Ferries" />
          </div>
          <div className="col-md text-center align-self-center">
            <i className="fas fa-copyright" /> {new Date().getFullYear()}, Fire
            Island Ferries, Inc.
            <br />
            <Link to="/info/privacy">Terms & Privacy</Link> v1.0
          </div>
          <div className="col-md text-center align-self-center icons">
            <a
              href="https://www.facebook.com/FIFerries/"
              rel="noreferrer noopener"
              target="_blank"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a
              href="https://twitter.com/fiferries"
              rel="noreferrer noopener"
              target="_blank"
            >
              <i className="fab fa-twitter" />
            </a>
            <a
              href="https://www.instagram.com/fiferries/"
              rel="noreferrer noopener"
              target="_blank"
            >
              <i className="fab fa-instagram" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <Warning />
  </footer>
);

export default Footer;
